import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import UiTable from "../../../../common/Table/UiTable";
import { Grid, makeStyles, Button, ButtonBase, Switch, Avatar } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import SelectColumnFilter from "../../../../common/Table/selectColumnFilter";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import PauseIcon from "@material-ui/icons/Pause";
import { changeStatusOfReport } from "services/Api/api";
// import successSnackbar from "utils/successSnackbar";
import { HttpErrorHandler } from "utils/ErrorHandlers/HttpErrorHandler";
import useApi from "hooks/useApi";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch } from "react-redux";
import { enqueueSnackbar as enqueueSnackbarAction, closeSnackbar as closeSnackbarAction } from "redux/actions/NotifierActions";
import CloseIcon from "@material-ui/icons/Close";
import { fetchReverseSyncDashboardData } from "../../../../redux/actions/dashboardActions";
import { Delete } from "@material-ui/icons";
import TransformTable from "common/Table/UiTable";

// import CheckPermissionFunction from "services/CheckPermissionFunction";
// import ConfiguredReportActionButtons from "components/common/ActionButtonGroups/ConfiguredReportActionButtons";
import Image from "../../../../common/UIComponents/Image";
import { Link } from "react-router-dom";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Config from "constants/config";
// import ConfiguredSyncsActionButtons from "components/common/ActionButtonGroups/ConfiguredSyncsActionButtons";
import SyncssActionButtons from "common/ActionButtonGroups/SyncsActionsButtons";
import RowSelectTable from "common/Table/RowSelectTable";
import CheckForPermissionHOC from "utils/CheckForPermissionHOC";
import moment from "moment";
import WorkspaceActionButton from "common/ActionButtonGroups/WorkspaceActionButton";
const closeSnackbar = (dispatch, ...args) => dispatch(closeSnackbarAction(...args));
const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        marginRight: 4,
        fontSize: 17,

        cursor: "pointer",
        transition: "initial",
        transitionDuration: "0.2s",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    headerButton: {
        width: "46px",
        height: "24px",
        background: "#2E394B 0% 0% no-repeat padding-box",
        border: "1px solid #2E394B",
        borderRadius: "4px",
        fontSize: 10,
        color: "#FFFFFF",
        marginRight: 5,
        "&:hover": {
            color: "#2E394B",
        },
    },
}));

const CustomSwitch = withStyles({
    switchBase: {
        color: "#A5AEB7",
        "&$checked": {
            color: "#23BE82",
        },
        "&$checked + $track": {
            backgroundColor: "#23BE82",
        },
    },
    checked: {},
    track: {},
})(Switch);

export default function WorkspaceTable({ tableData }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const colorList = [
        { bg: "rgba(222, 219, 255, 1)", text: "rgba(137, 127, 251, 1)" },
        { bg: "rgba(255, 241, 219, 1)", text: "rgba(227, 180, 108, 1)" },
        { bg: "rgba(219, 244, 255, 1)", text: "rgba(91, 176, 212, 1)" },
        { bg: "rgba(255, 234, 234, 1)", text: "rgba(212, 91, 91, 1)" },
    ];

    // const editPermission = CheckPermissionFunction(["report.edit"]);
    const [skipPageReset, setSkipPageReset] = React.useState(true);
    let selecetedConfiguredId = [];

    const setSelectedCallback = (rows) => {
        let arr = [];
        rows.forEach((element) => {
            const ele = { ...element };
            arr.push(ele.original.id);
        });
        selecetedConfiguredId = arr;
    };
    const columns = [
        {
            Header: "Workspace Id",
            accessor: "workspace_id",
            sortable: true,
            Cell: (props) => (
                <Grid container justify="space-between" alignItems="center">
                    <Grid container xs={10} alignItems="center">
                        <Typography className={false ? "hoverable" : null} variant="subtitle1">
                            {props.row.original.workspace_id}
                        </Typography>
                        {/* </Link> */}
                    </Grid>
                </Grid>
            ),
        },
        {
            Header: "Workspace Name",
            accessor: "name",
            sortable: true,
            Cell: (props) => (
                <Grid container justify="space-between" alignItems="center">
                    <Grid container xs={10} alignItems="center">
                        <Typography className={false ? "hoverable" : null} variant="subtitle1">
                            {props.row.original.name}
                        </Typography>
                        {/* </Link> */}
                    </Grid>
                </Grid>
            ),
        },
        {
            Header: "Status",
            sortable: false,
            Cell: (props) => {
                return (
                    <Grid style={{ minWidth: 130 }} container justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1">{props.row.original.status}</Typography>
                    </Grid>
                );
            },
            accessor: "status",
            Filter: SelectColumnFilter,

            // Filter: SelectColumnFilter,
            // filter: 'includes',
        },
        {
            Header: "Created On",
            accessor: "created_at",
            Filter: false,
            sortable: true,
            Cell: (props) => (
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid container>
                        <Typography variant="subtitle1">
                            {moment(props.row.original.created_at).format("YYYY-MM-DD, hh:mm:ss A")}
                        </Typography>
                    </Grid>
                </Grid>
            ),
        },
        // {
        //     Header: "Users",
        //     accessor: "users",
        //     sortable: false,
        //     Filter: false,
        //     Cell: (props) => (
        //         <Grid container justify="center" alignItems="center">
        //             <Grid
        //                 item
        //                 xs={12}
        //                 container
        //                 justifyContent="center"
        //                 style={{
        //                     cursor: "pointer",
        //                 }}
        //             >
        //                 <AvatarGroup
        //                     max={4}
        //                     sx={{
        //                         "& .MuiAvatarGroup-avatar": {
        //                             width: 32,
        //                             height: 32,
        //                         },
        //                     }}
        //                     renderSurplus={(surplus) => (
        //                         <span style={{ fontSize: 13, fontWeight: 500 }}>+{surplus.toString()}</span>
        //                     )}
        //                 >
        //                     {props.row.original.users.map((user, i) => (
        //                         <Avatar
        //                             sx={{ bgcolor: colorList[i % 4].bg, color: colorList[i % 4].text, width: 32, height: 32 }}
        //                         >
        //                             {user.name[0]}
        //                         </Avatar>
        //                     ))}
        //                 </AvatarGroup>
        //             </Grid>
        //         </Grid>
        //     ),
        // },
        {
            Header: "Timezone",
            accessor: "timezone",
            sortable: true,
            Cell: (props) => (
                <Grid container justify="space-between" alignItems="center">
                    <Grid container xs={10} alignItems="center">
                        <Typography className={false ? "hoverable" : null} variant="subtitle1">
                            {props.row.original.timezone}
                        </Typography>
                        {/* </Link> */}
                    </Grid>
                </Grid>
            ),
        },
        {
            Header: "Deleted On",
            accessor: "deleted_at",
            Filter: false,
            sortable: true,
            Cell: (props) => (
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid container>
                        <Typography variant="subtitle1">
                            {props.row.original.deleted_at
                                ? moment(props.row.original.deleted_at).format("YYYY-MM-DD, hh:mm:ss A")
                                : null}
                        </Typography>
                    </Grid>
                </Grid>
            ),
        },
        {
            Header: "Actions",
            Filter: false,
            Cell: (props) => (
                <Grid container style={{ minWidth: 100 }}>
                      {props.row.original.status == "DELETED"
                                ?<></>
                                :   <WorkspaceActionButton  workspaceId={props.row.original.workspace_id}/>}
                  
                </Grid>
            ),
            sortable: false,
        },
    ];

    const dashboard = useSelector((state) => state.dashboard);
    const [data, setData] = React.useState(dashboard.reverseSyncReports);

    React.useEffect(() => {
        setData(dashboard.reverseSyncReports);
    }, [dashboard.reverseSyncReports]);

    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    return (
        <Box>
            <TransformTable
                columns={columns}
                data={tableData}
                setData={setData}
                updateMyData={updateMyData}
                skipPageReset={skipPageReset}
                link="/"
                showCreateBtn={false}
                componentName="orchestration"
            />
        </Box>
    );
}
