import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import clsx from "clsx";
import WarningPopup from "common/WarningPopup";
import useApi from "hooks/useApi";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        marginRight: 4,
        fontSize: 17,
        cursor: "pointer",
        transition: "initial",
        transitionDuration: "0.2s",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    font15: {
        fontSize: 15,
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

export default function WorkspaceActionButton({ workspaceId }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [CancelConsentOpen, setCancelConsentOpen] = React.useState(false);
    const [, deleteWorkspaceIsLoading, deleteWorkspace] = useApi();


    const deleteAdminWorkspace = async () => {
        try {
            const user = await deleteWorkspace ({
                module: 'adminUser',
                method: 'deleteAdminWorkspace',
                returnResult: true,
                apiData: {
                    workspaceId: workspaceId
                },
                successToast: "Workspace Deleted",

            })
            setCancelConsentOpen(false)
        }
        catch (e) {
            console.log(e)
        }

    }


    const cancelSubscriptionConsent = () => {
        setCancelConsentOpen(true);
    };

    return (
        <>
            <DeleteOutlinedIcon
                className={clsx(classes.icon)}
                fontSize="small"
                onClick={(e) => {
                    e.stopPropagation();
                    cancelSubscriptionConsent();
                }}
            />

            <WarningPopup
                openPopup={CancelConsentOpen}
                closePopup={() => setCancelConsentOpen(false)}
                headingText="Are you sure you want to delete this workspace?"
                submitAction={deleteAdminWorkspace}
                submitActionIsLoading={deleteWorkspaceIsLoading} 
            />
        </>
    );
}
