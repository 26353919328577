import React from "react";
import Table from "common/Table/ServerSideTable";
import { Grid, Button, Switch, Typography, Box, CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import useTableApi from "hooks/useTableApi";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import mainMoment from "moment";
import UiTable from "common/Table/UiTable";
import SelectColumnFilter from "common/SelectFilter";

import { makeStyles } from "@material-ui/core";
import { NEO_ACCOUNTS_DATA_LIST, NEO_ACCOUNTS_DATA_IS_LOADING } from "redux/actionTypes/actionTypes";
import { useNavigate } from "react-router-dom";
const moment = require("moment-timezone");

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        transition: "initial",
        transitionDuration: "0.2",
        marginRight: 4,
        fontSize: 17,
        "&:hover": {
            cursor: "pointer",
            fontSize: 20,
        },
    },
    headerButton: {
        width: "46px",
        height: "24px",
        background: "#2E394B 0% 0% no-repeat padding-box",
        border: "1px solid #2E394B",
        borderRadius: "4px",
        fontSize: 10,
        color: "#FFFFFF",
        marginRight: 5,
        "&:hover": {
            color: "#2E394B",
        },
    },
    boxSuccess: {
        height: 8,
        width: 8,
        borderRadius: "50%",
        backgroundColor: "#23BE82",
        marginRight: 10,
    },
    boxFailure: {
        height: 8,
        width: 8,
        borderRadius: "50%",
        backgroundColor: "#F77070",
        marginRight: 10,
    },
    partialSuccess: {
        height: 8,
        width: 8,
        borderRadius: "50%",
        backgroundColor: "#548EEA",
        marginRight: 10,
    },
    boxRunning: {
        height: 8,
        width: 8,
        borderRadius: "50%",
        backgroundColor: "#FD9E74",
        marginRight: 10,
    },
}));

/**
 * Component to render runs table
 * @component
 * @param {object} param0
 */
export default function NeoAccountsTable({ tableNavClick, refresh, to, from }) {
    const classes = useStyles();
    // const [formattingUnit, setFormattingUnit] = React.useState("MB")
    const [queryString, setQuertString] = React.useState("");
    const navigate = useNavigate();
    const [sortBy, setSortBy] = React.useState([]);
    const dispatch = useDispatch();
    const dcLogs = useSelector((state) => state.logsData);
    const [data, setData] = React.useState(dcLogs.neoAccountsData);
    const [skipPageReset, setSkipPageReset] = React.useState(false);
    const [tableData, count, page, pageSize, tableLoading, onPageChange, onFilterChange, onPageSizeChange] = useTableApi(
        `/admin_app/ask_neo/accounts`,
        refresh,
        false,
        queryString
    );
    React.useEffect(() => {
        const toString = moment(from).format("YYYY-MM-DD") + " 23:59:59";
        const fromString = moment(to).format("YYYY-MM-DD") + " 00:00:00";
        const sortColumn = sortBy[0]?.id;
        const sortDirection = sortBy[0]?.desc ? "desc" : "asc";
        console.log(sortColumn);
        console.log(sortDirection);
        let qS = `&from_date=${fromString}&to_date=${toString}&sort_column=${sortColumn}&sort_direction=${sortDirection}`;

        if (sortColumn === undefined) {
            qS = `&from_date=${fromString}&to_date=${toString}&sort_direction=${sortDirection}`;
        }
        console.log(qS);
        setQuertString(qS);
    }, [from, to, sortBy]);

    React.useEffect(() => {
        setData(tableData);
        if (tableData?.length > 0) {
            dispatch({ type: NEO_ACCOUNTS_DATA_LIST, payload: tableData });
        }
    }, [tableData]);

    React.useEffect(() => {
        dispatch({ type: NEO_ACCOUNTS_DATA_IS_LOADING, payload: tableLoading });
    }, [tableLoading]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Account id",
                sortable: true,
                accessor: "account_id",
                Cell: (props) => (
                    <Grid container justifyContent="space-between" alignItems="center" style={{ width: 50 }}>
                        <Typography variant="subtitle1">{props.row.original.account_id}</Typography>
                    </Grid>
                ),
            },
            {
                Header: "Account name",
                sortable: true,
                accessor: "account_name",
                Cell: (props) => (
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={() => navigate(`/accounts/details/${props.row.original?.account_id}`)}
                        style={{ cursor: "pointer" }}
                    >
                        <Typography variant="subtitle1">{props.row.original.account_name}</Typography>
                    </Grid>
                ),
            },
            {
                Header: "Plan name",
                Filter: false,
                sortable: true,
                Filter: SelectColumnFilter,
                accessor: "plan_name",
                Cell: (props) => (
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1">{props.row.original.plan_name}</Typography>
                    </Grid>
                ),
            },
            {
                Header: "No of Questions",
                Filter: false,
                sortable: true,
                accessor: "n_questions",
                Cell: (props) => (
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1">{props.row.original.n_questions}</Typography>
                    </Grid>
                ),
            },
            {
                Header: "Tokens",
                Filter: false,
                sortable: true,
                accessor: "sum_tokens",
                Cell: (props) => (
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1">{props.row.original.sum_tokens}</Typography>
                    </Grid>
                ),
            },
        ],
        []
    );
    // const [tableData, count, page, pageSize, tableLoading, onPageChange, onFilterChange, onPageSizeChange] = useTableApi(
    //     `/admin_app/get_db_customer`,
    //     refresh
    // );
    React.useEffect(() => {
        setData(dcLogs.neoAccountsData);
    }, [dcLogs.neoAccountsData]);

    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    if (dcLogs.neoAccountsDataIsLoading && dcLogs.neoAccountsData?.length === 0) {
        return (
            <Grid container justify="center" alignItems="center" style={{ height: "85vh" }}>
                <CircularProgress />
            </Grid>
        );
    }
    return (
        <Box>
            {/* <Table
                columns={columns}
                data={tableData}
                count={count}
                tableLoading={tableLoading}
                onPageChange={onPageChange}
                currentPage={page}
                onFilterChange={onFilterChange}
                perPage={pageSize}
                onPageSizeChange={onPageSizeChange}
            /> */}

            <UiTable
                columns={columns}
                data={tableData}
                setData={setData}
                updateMyData={updateMyData}
                skipPageReset={skipPageReset}
                showCount={true}
            />
        </Box>
    );
}
