import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class AdminUserEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `admin_app`;
        this.transformer = null;
    }
    getAdminUser(data) {
        return axios.get(`${this.resourceEndpoint}/get_user/${data.apiData.userId}`);
    }
    deleteAdminUser(data) {
        return axios.delete(`${this.resourceEndpoint}/delete_admin_user/${data.apiData.userId}`);
    }
    getAdminRoles() {
        return axios.get(`${this.resourceEndpoint}/get_admin_roles`);
    }
    editAdminUser(data) {
        console.log(data)
        return axios.post(`${this.resourceEndpoint}/edit_user_role/${data.apiData.data.user_id}`, data.apiData.data)
    }
    addAdminUser(data) {
        return axios.post(`${this.resourceEndpoint}/add_admin_user`, data.apiData.data)
    }
    addAdminUserExisting(data) {
        return axios.post(`${this.resourceEndpoint}/add_admin_user/existing`, data.apiData.data)
    }
    getAllAccounts() {
        return axios.get(`${this.resourceEndpoint}/all_accounts`)
    }
    deleteAdminWorkspace(data) {
        return axios.delete(`${this.resourceEndpoint}/delete_workspace/${data.apiData.workspaceId}`)
    }
}

export default AdminUserEndpoints;
